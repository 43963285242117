//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}



.avatar-container {
	position: relative;
	display: inline-block;
  }
  
  
  .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.3s ease;
  }
  
  .avatar-container:hover .overlay {
	opacity: 1;
  }
  
  .edit-button {
	border: none;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
  }
  